import { Box, Step, StepButton, Stepper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FullPageLoadingCircle } from '../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import AMLQuestionAnswerDTO from '../../models/clientQuestionnaires/AMLQuestionAnswerDTO';
import ClientQuestionnairesDTO from '../../models/clientQuestionnaires/ClientQuestionnairesDTO';
import QuestionsType from '../../models/clientQuestionnaires/QuestionsType';
import { ClientDto } from '../../models/clients/ClientDto';
import { AMLQuestionService } from '../../repository/AMLQuestionService';
import { ClientTokenService } from '../../repository/ClientTokenService';
import { ProcedureService } from '../../repository/ProcedureService';
import QuestionsService from '../../repository/QuestionsService';
import { ErrorHandler } from '../../utils/ErrorHandler';
import './AMLProcedure.scss';
import { AMLProcedureMiddleContent } from './AMLProcedureMiddleContent/AMLProcedureMiddleContent';
import { AMLProcedureWrapper } from './AMLProcedureWrapper/AMLProcedureWrapper';
import { AMLQuestion } from './AMLQuestion/AMLQuestion';
import { AMLVerification } from './AMLVerification/AMLVerification';

export function AMLProcedure() {
  let navigate = useNavigate();

  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);

  const [userData, setUserData] = useState<ClientDto>();

  const [questions, setQuestions] = useState<ClientQuestionnairesDTO[]>([]);

  const { token } = useParams();

  const { register, setValue, getValues, watch } = useForm();

  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false);

  const [questionTypesList, setQuestionTypeList] = useState<QuestionsType[]>(
    []
  );

  const [answerOnQuestions, setAnswerOnQuestion] = useState<AMLQuestionAnswerDTO[]>([]);

  const [procedureSubmitted, setProcedureSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllQuestionType();
  }, []);

  const getAllQuestionType = () => {
    QuestionsService.getAllQuestionsType()
      .then((data) => {
        setQuestionTypeList(data.data);
      })
      .catch(() => {
      });
  };

  const handleVerificationComplete = () => {
    setIsVerificationCompleted(true);
  };

  const validateToken = async (token: string) => {
    await ClientTokenService.validateClientToken(token)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        var customMessage = t('START_NEW_AML_PROCEDURE');
        SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
      });
  };

  const getPersonQuestions = () => {
    AMLQuestionService.getAMLPersonGroupQuestions(token).then(
      (response: ClientQuestionnairesDTO[]) => {
        setQuestions(response);
      }
    );
  };
  useEffect(() => {
    if (!token) {
      navigate('/error');
      return;
    }
    ProcedureService.existsAMLProcedureByClientToken(token).then((res) => {
      if (res == false) {
        validateToken(token).then(() => {
          getPersonQuestions();
        });
      } else {
        SwalAlert.errorAlert(
          t('ERROR'),
          t('ALREADY_CREATED_AML_PROCEDURE')
        );
      }
    });
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleStep = (step: number) => () => {
    if (answerOnQuestions[step] !== undefined) {
      setActiveStep(step);
    }
  };
  const checkIfAnswered = (answer: AMLQuestionAnswerDTO) => {
    if (
      (answer?.files && answer.files?.length > 0) ||
      (answer?.countries && answer.countries?.length > 0) ||
      answer?.freeTextAnswer ||
      (answer?.nomenclatureEntryIds && answer.nomenclatureEntryIds.length > 0)
    ) {
      return true;
    }
    return false;
  };
  const getQuestionTypeByQuestionId = (id: number) => {
    var element = questionTypesList.find((qt) => qt.id === id);
    return element?.type;
  };
  const handleSaveAnswer = (
    index: number | string,
    amlAnswer: AMLQuestionAnswerDTO
  ) => {
    if (typeof index == 'string') {
      index = parseInt(index);
    }
    answerOnQuestions[index - 1] = amlAnswer;
    setAnswerOnQuestion(answerOnQuestions);
  };

  const handleSubmitAmlProcedure = () => {
    setLoading(true);
    AMLQuestionService.createAMLProcedure(
      answerOnQuestions,
      window.location.href.split('/').at(-1)
    ).then((data) => {
      setProcedureSubmitted(true);
      setLoading(false);
    }).catch((e) => {
      const errorMessage = ErrorHandler.parseErrorMessage(e);
      setLoading(false);
      SwalAlert.errorAlert(t('ERROR'), errorMessage);
    });
  };
  if (procedureSubmitted) {
    return (
      <AMLProcedureWrapper>
        <AMLProcedureMiddleContent>
          <Typography variant={'h5'} textAlign={'center'} color={'primary'} marginBottom={3}><b>{t('QUESTIONNAIRE_SUBMITTED_SUCCESSFULLY')}</b></Typography>
          <Typography variant={'h6'} textAlign={'center'}>
            {t('QUESTIONNAIRE_SUBMITTED_SUCCESSFULLY_MESSAGE')}
          </Typography>
          <Typography variant={'h6'} textAlign={'center'}>
            {t('AML_YOU_CAN_CLOSE_THIS_WINDOW_MESSAGE')}
          </Typography>
        </AMLProcedureMiddleContent>
      </AMLProcedureWrapper>
    );
  } else {
    return (
      <AMLProcedureWrapper>
        {!isVerificationCompleted ? (
          <AMLVerification onNext={handleVerificationComplete}/>
        ) : (
          <>
            {questions.map(
              (question: ClientQuestionnairesDTO, index: number) =>
                activeStep === index && (
                  <AMLQuestion
                    key={index}
                    question={question.amlQuestionDTO}
                    type={getQuestionTypeByQuestionId(
                      question?.amlQuestionDTO?.amlQuestionTypeId!
                    )}
                    index={index + 1}
                    sizeQuestion={questions.length}
                    answers={question.nomenclatureEntryDtoList}
                    description={question.amlQuestionDTO.description}
                    onNext={handleNext}
                    answerOnQuestion={
                      answerOnQuestions && answerOnQuestions[index]
                    }
                    onBack={handleBack}
                    files={question.amlQuestionFileDtos}
                    register={register}
                    handleSubmit={handleSubmitAmlProcedure}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                    userData={userData}
                    handleSaveAnswer={handleSaveAnswer}/>
                )
            )}
            <Box className='aml-stepper-box'>
              <Stepper
                nonLinear
                activeStep={activeStep}
                className='aml-stepper'>
                {questions.map((_: any, index: number) => (
                  <Step
                    key={index}
                    completed={checkIfAnswered(answerOnQuestions[index])}>
                    <StepButton
                      color='inherit'
                      onClick={handleStep(index)}
                    ></StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </>
        )}
        <FullPageLoadingCircle loading={loading}/>
      </AMLProcedureWrapper>
    );
  }
}
