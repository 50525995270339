import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Alert, Box, Grid, TextField, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FileUploadInput from '../../../../components/FormFIelds/FIleUploadInput';
import { FullPageLoadingCircle } from '../../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import { ClientService } from '../../../../repository/ClientService';
import './client-conversation-wrapper-page.scss';
import { useTranslation } from 'react-i18next';

const ClientMlroConversation = () => {
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get('token');
  const [message, setMessage] = useState<string | undefined>();
  const [newMessage, setNewMessage] = useState<string>('');
  const [showSinner, setShowSpinner] = useState<boolean>(false);
  const [successSendMessage, setSuccessSendMessage] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [isExpiredLink, setIsExpiredLink] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      getMessage();
    }
  }, [token]);

  const getMessage = () => {
    ClientService.getMessage(token!).then((data) => {
      if(data.message){
        setIsExpiredLink(true);
      }
    }).catch(() => {
      setIsExpiredLink(true);
    });
  };

  const handleCloseSpinner = () => {
    setShowSpinner(false);
  };

  const handleFileChange = (files: File[]) => {
    setFiles(files);
  };

  const getFileDto = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          name: file.name,
          mimeType: file.type,
          base64Content: reader.result
        });
    });
  };

  const initiateCommunication = (e: FormEvent) => {
    e.preventDefault();
    const filePromises = files.map((file: File) => getFileDto(file));
    Promise.all(filePromises).then((fileDtos) => {
      const data = {
        message: newMessage,
        token: token,
        files: fileDtos
      };
      setShowSpinner(true);
      ClientService.answerToMRLO(data).then(() => {
        setNewMessage('');
        setShowSpinner(false);
        setSuccessSendMessage(true);
      }).catch(() => setShowSpinner(false));
    });
  };

  return (<Grid
    container
    className='grid client-mlro-page'
    justifyContent='center'
    alignItems='center'>
    <Grid item className='form client-mlro-page-form-wrapper'
          justifyContent='center'
          alignItems='center'>
      <Grid item justifyContent='center' alignItems='center' className={'conversation-title-wrpper'}>
        <Box className='client-mlro-page-header-wrapper' justifyContent='center'>
          <Typography
            textAlign='center'
            variant='h6'>
            {t('PROCEDURE_CONVERSATION')}
          </Typography>
        </Box>
      </Grid>
      <Grid item width='100%'>
        {!isExpiredLink ? <Box padding={3} paddingTop={2} className={'message-wrapper'}>
            <Box>
              <Box className={'conversation-message-card'} marginY={2} padding={1}>
                <Box display={'flex'} alignItems={'center'}>
                  <Avatar className={'client-mlro-conversation-avtar'}>
                    <ManageAccountsIcon/>
                  </Avatar>
                  <b>{t('MLRO_ADMIN')}</b>
                </Box>
                <Box paddingLeft={5} marginLeft={1.2}>
                  {message}
                </Box>
              </Box>
            </Box>
            {successSendMessage ?
              <Alert severity='success'>{t('MESSAGE_SUCCESSFULLY_SENT')}</Alert> :
              <form onSubmit={initiateCommunication}>
                <Box className={'client-mlro-enter-message-wrapper'} padding={2}>
                  <TextField
                    id='procedureConversation'
                    label={t('ENTER_MESSAGE')}
                    multiline
                    fullWidth={true}
                    color={'primary'}
                    value={newMessage}
                    maxRows={8}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setNewMessage(e.target.value)}>
                  </TextField>
                  <Box alignItems={'center'} marginTop={3} marginBottom={2}>
                    <FileUploadInput
                      label={t('UPLOAD_FILES')}
                      classes={'file-input-wrapper'}
                      selectedFiles={files}
                      onFileChange={handleFileChange}
                      index={0}
                      name={'files'}/>
                  </Box>
                  <Box textAlign={'end'} marginTop={3}>
                    <Button variant='contained' color='primary' className='addButton' type={'submit'} disabled={newMessage.trim() === ''}>
                      {t('SEND')}
                    </Button>
                  </Box>
                </Box>
              </form>
            }
            <FullPageLoadingCircle loading={showSinner} handleClose={handleCloseSpinner}/>
          </Box>
          : <Box className={'expired-link-wrapper'}>
            <Box className={'expired-link-message'}>{t('MESSAGE_PROVIDED')}</Box>
          </Box>
        }
      </Grid>
    </Grid>
  </Grid>);
};
export default ClientMlroConversation;
