import axios from '../axios/axios';
import { FileDTO } from '../models/aml/FileDTO';

export class TermsConditionService {
  static async getFilesForTermsAndConditions(): Promise<FileDTO[]> {
    const response = await axios.get('/file/public/token-files');
    return response.data;
  }

  static async termsAndConditionsVerification(
    isNotPep: boolean,
    tokenPresaleTerms: boolean,
    politicallyExposedPerson: boolean,
    token: string
  ): Promise<void> {
    await axios.post(
      '/kyc-procedure/public/terms-and-conditions',
      { isNotPep, tokenPresaleTerms, politicallyExposedPerson },
      {
        params: {
          token
        }
      }
    );
  }
}
