import { css } from 'aphrodite/no-important';
import { ProcedureType } from '../LandingPage/constants/ProcedureType';
import {
  IJourneyEventMetaData,
  IJourneyState,
  JourneyContainer,
  InputProvider
} from './../../lib/idscan/idscan-jcs';
import * as React from 'react';
import { textTranslation } from './translations';
import styles from './styles';
import { useEffect, useState } from 'react';
import KYCProcedureService from '../../repository/KYCProcedureService';
import { QRCodeResponse } from '../../models/procedure/QRCodeResponse';
import { useTranslation } from 'react-i18next';

interface ICurrentJourneyState {
  event: string;
  meta?: IJourneyEventMetaData;
  state?: IJourneyState;
}

interface GbgJourneyContainerProps {
  scanningToken: string;
  userEmail: string | undefined;
  onJourneyCompleted: () => void;
  clientToken: string | undefined;
  onQRCodeReceived: (qrCode: QRCodeResponse) => void;
}

interface IState {
  history: ICurrentJourneyState;
  isLoading: boolean;
}

export default function GbgJourneyContainer(props: GbgJourneyContainerProps) {
  const [state, setState] = useState<IState>({
    history: {
      event: ''
    },
    isLoading: true
  });
  const [container, setContainer] = useState<JourneyContainer | undefined>();
  const { t } = useTranslation();

  async function onJourneyEvent(
    event: string,
    meta: IJourneyEventMetaData,
    state: IJourneyState
  ): Promise<void> {
    if (event === 'JOURNEY:END') {
      props.onJourneyCompleted();
    }
    if (event === 'JOURNEY:START') {
      let qrCode = await KYCProcedureService.QRCode(
        process.env.REACT_APP_GBG_JOURNEY!,
        props.scanningToken,
        props.clientToken!
      );

      if (props.onQRCodeReceived) {
        props.onQRCodeReceived(qrCode.data);
      }
    }
    setState({
      history: { event, meta, state },
      isLoading: event === 'TRANSFER:PROGRESS'
    });
  }

  const initContainer = () => {
    const container = new JourneyContainer({
      auth: false, // backendUrl: 'https://idscan.degree53.com:443',
      backendUrl: process.env.REACT_APP_GBG_HOST!,
      container: '#gbgIdScan',
      fileUploadOnCameraCaptureJourneys: true,
      dictionary: textTranslation,
      onJourneyEvent: onJourneyEvent,
      previewPages: {
        documentStep: {
          smartCapture: false,
          manualCapture: false
        },
        facematchStep: {
          manualCapture: false
        },
        poaStep: {
          manualCapture: false
        },
        passiveLivenessStep: {
          manualCapture: false
        },
        covidStep: {
          manualCapture: false
        }
      },
      manualCapture: {
        enabled: true,
        timeout: 15
      },
      assetsDirectory: '/assets',
      smartCapture: {
        workerScriptUrl: '/idscan/ides-micro.a585b9f34a2f0475d118.js',
        asmScriptUrl: '/idscan/idesmicro_asm.js',
        timeout: 5000,
        timeLogsEnabled: false //for smartcapture timnig logs
      },
      isVerboseLogEnabled: false, //for general app timing logs
      hideLogo: true,
      hideAutoCaptureHints: false,
      token: props.scanningToken,
      journeyDefinitionId: process.env.REACT_APP_GBG_JOURNEY,
      allowedInputProviders: [
        InputProvider.CAMERA,
        InputProvider.FILESYSTEM,
        InputProvider.SCANNER,
        InputProvider.SMART_CAPTURE
      ],
      additionalData: [
        {
          name: 'clientEmail',
          value: props.userEmail ?? '',
          step: '',
          type: 'string'
        },
        {
          name: 'CustomerNumber',
          value: props.clientToken ?? '',
          step: '',
          type: 'string'
        }
      ]
    });

    setContainer(container);
    container.initialize();
  };

  useEffect(() => {
    initContainer();
    return container && container.terminate();
  }, []);

  const renderError = (item: ICurrentJourneyState) => {
    switch (item.event) {
      case 'ERROR':
      case 'TRANSFER:FAILED':
        return (
          <div>
            {state.history.event}
            <br/>
            {JSON.stringify(state.history.meta)}
            <br/>
            {JSON.stringify(state.history.state)}
          </div>
        );
      default:
        return null;
    }
  };

  const render = (): React.JSX.Element => {
    const classNames = {
      page: css(styles.page),
      heading: css(styles.heading),
      reactLogo: css(styles.reactLogo),
      content: css(styles.content),
      loaderContainer: css(styles.loaderContainer),
      loader: css(styles.loader)
    };
    return (
      <>
        <div className={classNames.page}>
          <div className={classNames.content} id='gbgIdScan'>
            {t('LOADING')}
          </div>
          {state.isLoading && (
            <div className={classNames.loaderContainer}>
              <div className={classNames.loader}/>
            </div>
          )}
          <div>{renderError(state.history)}</div>
        </div>
      </>
    );
  };

  return <>{render()}</>;
}
