import decode from 'jwt-decode';
import { FileDTO } from '../models/aml/FileDTO';

const ApplicationService = {
  getBase64: (file: File) => {
    return new Promise<string>((resolve, reject) => {
      // Make new FileReader
      let reader = new FileReader();

      // Set up a load event listener
      reader.onload = () => {
        if (reader.result !== null && typeof reader.result === 'string') {
          // Convert the result to base64 string
          let base64String = reader.result.split(',')[1]; // Remove data URL prefix
          resolve(base64String);
        } else {
          reject(new Error('Failed to read file.'));
        }
      };

      // Set up an error event listener
      reader.onerror = () => {
        reject(new Error('Error reading the file.'));
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    });
  },
  base64ToBlob: (base64: string, mime: string) => {
    const byteString = atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mime });

  },
  async fileToFileDto(file: File): Promise<FileDTO> {
    let base64FromFile: any = await this.getBase64(
      file
    )
      .then((result) => {
        return result;
      })
      .catch(() => {
      });
    return new FileDTO({
      name: file.name,
      mimeType: file.type,
      base64Content: base64FromFile
    });
  },
  async filesToFilesDto(files: File[]): Promise<FileDTO[]> {
    let filesDto: FileDTO[] = [];

    for (let i = 0; i < files.length; i++) {
      filesDto.push(
        await this.fileToFileDto(files[i]).then((res) => {
          return res;
        })
      );
    }
    return filesDto;
  }
};
export default ApplicationService;
