import { Box, Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, SelectChangeEvent, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import KYCProcedureService from 'repository/KYCProcedureService';
import address from '../../assets/images/address.svg';
import camera from '../../assets/images/camera.svg';
import id from '../../assets/images/id.svg';
import { FullPageLoadingCircle } from '../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import { ProofOfAddress } from './ProofOfAddress/ProofOfAddress';
import SwalAlert from '../../components/SwalAlert/SwalAlert';
import { FileDTO } from '../../models/aml/FileDTO';
import { ClientBasicData } from '../../models/clients/ClientBasicData';
import { RepresentativeAnswersResponse } from '../../models/legal_entity/RepresentativeAnswersResponse';
import { ShareholderRequest } from '../../models/legal_entity/ShareholderRequest';
import { QRCodeResponse } from '../../models/procedure/QRCodeResponse';
import { ClientTokenService } from '../../repository/ClientTokenService';
import GBGTokenService from '../../repository/GBGTokenService';
import RepresentativeAnswersService from '../../repository/RepresentativeAnswersService';
import { TermsConditionService } from '../../repository/TermsConditionService';
import { ErrorHandler } from '../../utils/ErrorHandler';
import GbgJourneyContainer from '../GbgJourneyContainer';
import { ProcedureType } from './constants/ProcedureType';
import './LandingPage.scss';
import { LandingPageStep } from './LandingPageStep';
import { LegalEntityForm } from './LegalEntityForm/LegalEntityForm';
import { RepresentativeAnswers } from './RepresentativeAnswers/RepresentativeAnswers';
import { Shareholder } from './Shareholders/Shareholder';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import AddIcon from '@mui/icons-material/Add';

const LandingPage = () => {
  const [step, setStep] = useState<LandingPageStep>(
    LandingPageStep.IDENTITY_VERIFICATION
  );
  let navigate = useNavigate();
  const [userData, setUserData] = useState<ClientBasicData>();
  const [scanningToken, setScanningToken] = useState<string>('');
  const [filesForTermsAndConditionals, setFilesForTermsAndConditionals] = useState<FileDTO[]>([]);
  const { token } = useParams();
  const [isVerificationCompleted, setIsVerificationCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState<QRCodeResponse | undefined>();
  const { t } = useTranslation();
  const [procedureType, setProcedureType] = React.useState<ProcedureType | null>(ProcedureType.INDIVIDUAL);
  const [shareholders, setShareholders] = useState<ShareholderRequest[]>(
    [{ firstName: '', lastName: '', email: '', phoneNumber: '', shareholderType: '', shareholderRole: '' }]);
  const [representativeAnswersResponse, setRepresentativeAnswersResponse] = useState<RepresentativeAnswersResponse>();

  function updateQRCode(qrCode: QRCodeResponse): void {
    setQrCode(qrCode);
  }

  const setShareholder = (index: number, shareholder: ShareholderRequest) => {
    const newShareholdersList = shareholders?.map((e, i) => {
      if (index === i) {
        return shareholder;
      }
      return e;
    });

    setShareholders(newShareholdersList);
  };

  const validateToken = useCallback(async (token: string) => {
    await ClientTokenService.validateClientToken(token)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((e) => {
        const errorMessage = ErrorHandler.parseErrorMessage(e);
        var customMessage = t('START_NEW_KYC_PROCEDURE');
        SwalAlert.errorAlert(t('ERROR'), errorMessage + ' ' + customMessage);
      });
  }, []);

  useEffect(() => {
    getFileForTermsAndConditions();

    function hasGbgResponseByProcedureToken(procedureType: ProcedureType) {
      KYCProcedureService.findKycProcedureProgressByToken(token!).then((res) => {
        const { isCompleted, isGbgCompleted } = res.data;
        if (isCompleted || isGbgCompleted) {
          clearInterval(interval);
        }
        if (isCompleted) {
          setStep(LandingPageStep.TERMS_AND_CONDITIONS);
          setIsVerificationCompleted(true);
        } else if (isGbgCompleted) {
          if (procedureType === ProcedureType.LEGAL_ENTITY) {
            getRepresentativeAnswersData();
          }
          handleJourneyCompleted();
        }
      });
    }

    let interval: any;
    if (procedureType) {
      hasGbgResponseByProcedureToken(procedureType!);
      interval = setInterval(() => hasGbgResponseByProcedureToken(procedureType!), 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [procedureType]);

  const createProcedure = async () => {
    KYCProcedureService.existsByToken(token!).then((res) => {
      if (!res) {
        KYCProcedureService.createKYCProcedure(token!).then((res) => {
          if (procedureType === ProcedureType.INDIVIDUAL) {
            KYCProcedureService.setIndividualType(res.procedureId);
            setStep(LandingPageStep.ID_DOCUMENT);
          } else if (procedureType === ProcedureType.LEGAL_ENTITY) {
            setStep(LandingPageStep.LEGAL_ENTITY_ANSWERS);
            KYCProcedureService.setLegalEntityType(res.procedureId);
          }
        });
      }
    });
  };

  const getFileForTermsAndConditions = () => {
    TermsConditionService.getFilesForTermsAndConditions().then(
      (response: FileDTO[]) => setFilesForTermsAndConditionals(response)
    );
  };

  const accessDenied = async () => {
    navigate('/denied');
  };

  const getRepresentativeAnswersData = () => {
    RepresentativeAnswersService.getByKycProcedureToken(token!).then((res) => {
      setRepresentativeAnswersResponse(res);
    });
  };

  useEffect(() => {
    if (!token) {
      accessDenied();
      return;
    }
    if (scanningToken === '') {
      GBGTokenService.generateScanningToken().then((res) => {
        setScanningToken(res);
      });
    }
    KYCProcedureService.getProcedureTypeByToken(token).then((res) => {
      if (res) {
        setProcedureType(res);
      }
    });

    KYCProcedureService.findKycProcedureProgressByToken(token).then((res) => {
      const { procedureTypeChosen, isCompleted, isGbgCompleted, isLegalEntityCompleted, isRepresentativeAnswerCompleted, shareholdersCompleted } = res.data;
      if (isCompleted) {
        setStep(LandingPageStep.TERMS_AND_CONDITIONS);
        setIsVerificationCompleted(true);
      } else if (!isCompleted) {
        validateToken(token).then(() => {
          if (procedureTypeChosen && !isGbgCompleted && !isLegalEntityCompleted && !isRepresentativeAnswerCompleted) {
            if (procedureType === ProcedureType.INDIVIDUAL) {
              setStep(LandingPageStep.ID_DOCUMENT);
            } else if (procedureType === ProcedureType.LEGAL_ENTITY) {
              setStep(LandingPageStep.LEGAL_ENTITY_ANSWERS);
            }
          } else if (isGbgCompleted) {
            if (procedureType === ProcedureType.LEGAL_ENTITY) {
              getRepresentativeAnswersData();
            }
            handleJourneyCompleted();
          } else if (shareholdersCompleted) {
            setStep(LandingPageStep.ID_DOCUMENT);
          } else if (isRepresentativeAnswerCompleted) {
            setStep(LandingPageStep.SHAREHOLDERS);
          } else if (isLegalEntityCompleted) {
            setStep(LandingPageStep.REPRESENTATIVE_ANSWERS);
          }
        });
      }
    });
  }, [validateToken, scanningToken]);

  const handleJourneyCompleted = async () => {
    validateToken(token!).then(() => {
      setStep(LandingPageStep.PROOF_OF_ADDRESS);
    });

  };

  const submitVerification = () => {
    setLoading(true);
    TermsConditionService.termsAndConditionsVerification(
      true,
      true,
      true,
      token!
    ).then(() => {
      setIsVerificationCompleted(true);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };
  const handleChangeVerificationAnswer = (event: SelectChangeEvent) => {
    setProcedureType(event.target.value);
  };

  const handleAddShareholder = () => {
    setShareholders(shareholders => [...shareholders, { firstName: '', lastName: '', email: '', phoneNumber: '', shareholderType: '', shareholderRole: '' }]);
  };
  const handleRemoveShareholder = (index: number) => {
    const newShareholdersList = shareholders.filter((s, i) => {
      return i !== index;
    });

    setShareholders(newShareholdersList);
  };

  const saveShareholders = () => {
    KYCProcedureService.createShareholders(token!, shareholders).then((res) => {
      setStep(LandingPageStep.ID_DOCUMENT);
    });
  };
  const isValidationSuccessful = (): boolean => {
    let isValid = true;
    for (let i = 0; i < shareholders.length; i++) {
      if (shareholders[i].firstName.trim() === '' ||
        shareholders[i].lastName.trim() === '' ||
        shareholders[i].email.trim() === '' ||
        shareholders[i].phoneNumber.trim() === '' ||
        shareholders[i].shareholderType.trim() === '' ||
        shareholders[i].shareholderRole.trim() === '') {
        isValid = false;
        break;
      }
    }
    if (shareholders.length === 0) {
      isValid = false;
    }
    return isValid;
  };
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='landing-page'>
      {' '}
      <Paper elevation={12} className='paper-wrapper'>
        <Box display='flex' flexDirection='column' className='full-content'>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                {t('IDENTITY_VERIFICATION_STEPS')}
              </Typography>
            </Grid>
          </Grid>
          <Box flex={1} justifyContent='center' display='flex'>
            <Grid item xs={12} className='line'>
              <div className='stepper-wrapper'>
                <div className='stepper-item'>
                  <div
                    className={
                      'step-counter ' +
                      (step === LandingPageStep.LEGAL_ENTITY_ANSWERS ||
                      step === LandingPageStep.REPRESENTATIVE_ANSWERS ||
                      step === LandingPageStep.SHAREHOLDERS ||
                      step === LandingPageStep.ID_DOCUMENT ||
                      step === LandingPageStep.PROOF_OF_ADDRESS ||
                      step === LandingPageStep.TERMS_AND_CONDITIONS
                        ? 'completed-step-counter'
                        : '')
                    }>
                    1
                  </div>
                  <div className='step-name'>{t('IDENTITY_VERIFICATION_STEPS')}</div>
                </div>
                {procedureType === ProcedureType.LEGAL_ENTITY &&
                  <>
                    <div className='stepper-item'>
                      <div
                        className={
                          'step-counter ' +
                          (step === LandingPageStep.REPRESENTATIVE_ANSWERS ||
                          step === LandingPageStep.SHAREHOLDERS ||
                          step === LandingPageStep.ID_DOCUMENT ||
                          step === LandingPageStep.PROOF_OF_ADDRESS ||
                          step === LandingPageStep.TERMS_AND_CONDITIONS
                            ? 'completed-step-counter'
                            : '')
                        }>
                        2
                      </div>
                      <div className='step-name'>{t('LEGAL_ENTITY_INFO')}</div>
                    </div>
                    <div className='stepper-item'>
                      <div
                        className={
                          'step-counter ' +
                          (step === LandingPageStep.SHAREHOLDERS ||
                          step === LandingPageStep.ID_DOCUMENT ||
                          step === LandingPageStep.PROOF_OF_ADDRESS ||
                          step === LandingPageStep.TERMS_AND_CONDITIONS
                            ? 'completed-step-counter'
                            : '')
                        }>
                        3
                      </div>
                      <div className='step-name'>{t('REPRESENTATIVE_PERSON')}</div>
                    </div>
                    <div className='stepper-item'>
                      <div
                        className={
                          'step-counter ' +
                          (step === LandingPageStep.ID_DOCUMENT ||
                          step === LandingPageStep.PROOF_OF_ADDRESS ||
                          step === LandingPageStep.TERMS_AND_CONDITIONS
                            ? 'completed-step-counter'
                            : '')
                        }>
                        4
                      </div>
                      <div className='step-name'>{t('INPUT_SHAREHOLDERS')}</div>
                    </div>
                  </>
                }
                <div className='stepper-item'>
                  <div
                    className={
                      'step-counter ' +
                      (step === LandingPageStep.PROOF_OF_ADDRESS ||
                      step === LandingPageStep.TERMS_AND_CONDITIONS
                        ? 'completed-step-counter'
                        : '')
                    }>
                    {procedureType === ProcedureType.LEGAL_ENTITY ? 5 : 2}
                  </div>
                  <div className='step-name'>{t('ID_DOCUMENT')}</div>
                </div>
                <div className='stepper-item'>
                  <div
                    className={
                      'step-counter ' +
                      (step === LandingPageStep.TERMS_AND_CONDITIONS
                        ? 'completed-step-counter'
                        : '')
                    }>
                    {procedureType === ProcedureType.LEGAL_ENTITY ? 6 : 3}
                  </div>
                  <div className='step-name'>{t('PROOF_OF_ADDRESS')}</div>
                </div>
                <div className='stepper-item'>
                  <div
                    className={
                      'step-counter ' + (isVerificationCompleted ? 'completed-step-counter'
                        : '')
                    }>
                    {procedureType === ProcedureType.LEGAL_ENTITY ? 7 : 4}
                  </div>
                  <div className='step-name'>{t('TERMS_AND_CONDITIONS')}</div>
                </div>
              </div>
            </Grid>
          </Box>
          {step === LandingPageStep.IDENTITY_VERIFICATION && (
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              className='middle-content'>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item alignContent={'center'} textAlign={'center'}>
                  <Typography className='card-content-central-text'>
                    {t('IDENTITY_VERIFICATION')}:{' '}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems={'stretch'}
                justifyContent={'space-evenly'}
                rowGap={2}
                className='cards-wrapper'>
                <Grid
                  item
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  lg={3}
                  xl={3}
                  xs={12}
                  sm={10}
                  md={8}
                  className='middle-content-card'>
                  <Typography className='card-title'>{t('ID_DOCUMENT')}</Typography>
                  <img src={id} className='card-image'/>
                  <Typography className='card-content'>
                    {' '}
                    {t('IDENTIFICATION_DOCUMENT')}.{' '}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  lg={3}
                  xl={3}
                  xs={12}
                  sm={10}
                  md={8}
                  className='middle-content-card'>
                  <Typography className='card-title'>{t('LIVENESS_SCAN')}</Typography>
                  <img src={camera} className='card-image'/>
                  <Typography className='card-content'>
                    {t('LIVENESS_SCAN_INFO')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  lg={3}
                  xl={3}
                  xs={12}
                  sm={10}
                  md={8}
                  className='middle-content-card'>
                  <Typography className='card-title'>
                    {t('PROOF_OF_ADDRESS')}
                  </Typography>
                  <img src={address} className={'card-image'}/>
                  <Typography className='card-content'>
                    {' '}
                    {t('PROOF_OF_ADDRESS_INFO')}.{' '}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} justifyContent='center' className='start_verification_question_wrapper'> */}
              {/*   <Typography className='start_verification_question'>{t('INDIVIDUAL_OR_LEGAL_ENTITY_MESSAGE')}</Typography> */}
              {/*   <RadioGroup */}
              {/*     className='start_verification_question_answers' */}
              {/*     row */}
              {/*     value={procedureType} */}
              {/*     onChange={handleChangeVerificationAnswer} */}
              {/*     name='radio-buttons-group' */}
              {/*   > */}
              {/*     <FormControlLabel value={ProcedureType.INDIVIDUAL} control={<Radio/>} label={t('INDIVIDUAL')}/> */}
              {/*     <FormControlLabel value={ProcedureType.LEGAL_ENTITY} control={<Radio/>} label={t('LEGAL_ENTITY')}/> */}
              {/*   </RadioGroup> */}
              {/* </Grid> */}
              <Grid
                item
                xs={12}
                className='grid-button-start-verification'
                display='flex'
                justifyContent='center'>
                <Button
                  variant='contained'
                  disabled={procedureType === null}
                  onClick={createProcedure}
                  className='basic-button'>
                  {t('START_VERIFICATION')}
                </Button>
              </Grid>
            </Grid>
          )}
          {step === LandingPageStep.LEGAL_ENTITY_ANSWERS && (
            <Grid
              container
              className='middle-content'
              justifyContent='center'
              alignItems='center'>
              <Grid
                item
                container
                xs={12}
                justifyContent='center'
                alignItems='center'
                alignSelf={'center'}>
                <Grid item>
                  <Typography className='border card-title'>
                    {t('LEGAL_ENTITY_INFO')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <LegalEntityForm
                    email={userData?.email}
                    token={token}
                    onSave={() => setStep(LandingPageStep.REPRESENTATIVE_ANSWERS)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {step === LandingPageStep.REPRESENTATIVE_ANSWERS && (
            <Grid
              container
              className='middle-content'
              justifyContent='center'
              alignItems='center'>
              <Grid
                item
                container
                xs={12}
                justifyContent='center'
                alignItems='center'
                alignSelf={'center'}>
                <Grid item>
                  <Typography className='border card-title'>
                    {t('REPRESENTATIVE')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RepresentativeAnswers
                    token={token}
                    onSave={() => setStep(LandingPageStep.SHAREHOLDERS)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {step === LandingPageStep.SHAREHOLDERS && (
            <Grid
              container
              className='middle-content'
              justifyContent='center'
              alignItems='center'>
              <Grid
                item
                xs={12}
                justifyContent='center'
                alignItems='center'
                alignSelf={'center'}>
                <Grid item xs={12}>
                  <Typography className='border card-title'>
                    {t('INPUT_SHAREHOLDERS')}
                  </Typography>
                </Grid>
                <Grid item xs={12} className='shareholder-operations'>
                  <AddIcon onClick={handleAddShareholder} fontSize='large' className='shareholder-add-icon'/>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {shareholders.map((shareholder, index) => {
                    return (
                      <Shareholder
                        key={index}
                        shareholder={shareholder}
                        index={index}
                        onChange={setShareholder}
                        removeItem={handleRemoveShareholder}
                      />
                    );
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className='legal-entity-next-button'
                  display='flex'>
                  <Button
                    disabled={!isValidationSuccessful()}
                    variant='contained'
                    onClick={saveShareholders}>
                    {t('NEXT')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {step === LandingPageStep.ID_DOCUMENT && (
            <Grid
              container
              sx={{
                minHeight: '70vh',
                backgroundColor: '#EFEFEF'
              }}
              justifyContent='center'
              alignItems='center'>
              <Grid
                container
                alignItems={'stretch'}
                justifyContent={'space-evenly'}
                rowGap={2}>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item lg={8} sm={12}>
                      <Typography className='approved_identity_documents' variant='h6'>{t('APPROVED_IDENTITY_DOCUMENTS')}</Typography>
                      {scanningToken !== '' && <GbgJourneyContainer
                        scanningToken={scanningToken}
                        onJourneyCompleted={handleJourneyCompleted}
                        userEmail={userData?.email}
                        clientToken={token}
                        onQRCodeReceived={updateQRCode}
                      />}
                    </Grid>
                    <Grid item lg={4} sm={12} className='qr-div'>
                      <Typography className='qr-text'>
                        {t('SCAN_QR_INFO')}
                      </Typography>
                      <img className='kyc-qr' src={`data:image/png;base64,${qrCode?.data.QR_Code}`}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {step === LandingPageStep.PROOF_OF_ADDRESS && (
            <Grid
              container
              className='middle-content'
              justifyContent='center'
              alignItems='center'>
              <Grid
                item
                container
                xs={12}
                justifyContent='center'
                alignItems='center'
                alignSelf={'center'}>
                <Grid item>
                  <Typography className='border card-title'>
                    {t('PROOF_OF_ADDRESS')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {((procedureType === ProcedureType.INDIVIDUAL && userData) || (procedureType === ProcedureType.LEGAL_ENTITY && userData && representativeAnswersResponse)) &&
                    <ProofOfAddress
                      token={token}
                      fullName={userData?.fullName}
                      email={procedureType === ProcedureType.INDIVIDUAL ? userData?.email : representativeAnswersResponse?.email}
                      gbgBirthDate={userData?.birthDate}
                      onSave={() => setStep(LandingPageStep.TERMS_AND_CONDITIONS)}
                      phoneNumber={userData?.phoneNumber}
                    />}
                </Grid>
              </Grid>
            </Grid>
          )}
          {step === LandingPageStep.TERMS_AND_CONDITIONS && (
            <Grid
              container
              className='middle-content'
              justifyContent='center'
              alignItems='center'>
              <Grid
                container
                alignItems={'stretch'}
                justifyContent={'space-evenly'}
                rowGap={2}
                paddingTop={'15px'}>
                {!isVerificationCompleted ? (
                  <TermsAndConditions submitVerification={submitVerification} filesForTermsAndConditionals={filesForTermsAndConditionals}/>
                ) : (
                  <Grid item xs={12} sm={12} textAlign={'center'}>
                    <Typography variant={'h5'} color={'primary'} marginBottom={2}>
                      <b>{t('KYC_SUCCESSFULLY_SUBMITTED')}.</b>
                    </Typography>
                    <Typography variant={'h6'}>
                      {t('KYC_SUBMITTED_SUCCESSFULLY_MESSAGE')}
                    </Typography>
                    <Typography variant={'h6'}>
                      {t('KYC_YOU_CAN_CLOSE_THIS_WINDOW_MESSAGE')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
      <FullPageLoadingCircle loading={loading}/>
    </Box>
  );
};
export default LandingPage;
